import React from 'react'
import './testBar.css'

export default function testBar(props) {
    return (
        <section className="test-bar">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <span>Ambiente de Testes</span>
                    </div>
                </div>
            </div>
        </section>
    )
}