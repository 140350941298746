import React, { useState, useEffect, useRef } from 'react';
import InstructionsModal from './instructionsModal';

const Countdown = ({ timer, instructions, nextQuestion, handleAnswerCadidateTime }) => {
    const [seconds, setSeconds] = useState(0);
    const [started, setStarted] = useState(false);
    const myInterval = useRef(null);

    const startTimer = () => {
        if (!started) {
            setStarted(true);
            myInterval.current = setInterval(() => {
                setSeconds((prevSeconds) => {
                    if (prevSeconds > 0) {
                        return prevSeconds - 1;
                    } else {
                        if (timer > 0) {
                            nextQuestion(null, false, true);
                        }
                        return 0;
                    }
                });
            }, 1000);
        }
    };

    useEffect(() => {
        if (instructions) {
            clearInterval(myInterval.current);
        } else {
            startTimer();
        }

        return () => clearInterval(myInterval.current);
    }, [instructions]);

    useEffect(() => {
        setSeconds(timer);
        if (instructions) {
            setStarted(false);
            clearInterval(myInterval.current);
        }
    }, [timer, instructions]);

    useEffect(() => {
        handleAnswerCadidateTime(seconds);
    }, [seconds, handleAnswerCadidateTime]);

    const formatTime = (time) => String(time).padStart(2, '0');

    const counterHours = Math.floor(seconds / 3600);
    const counterMinutes = Math.floor((seconds % 3600) / 60);
    const counterSeconds = seconds % 60;
    const result = `${formatTime(counterHours)}:${formatTime(counterMinutes)}:${formatTime(counterSeconds)}`;

    return (
        <>
            <span>{timer > 0 ? result : ''}</span>
            {instructions && (
                <InstructionsModal
                    stopTimer={() => clearInterval(myInterval.current)}
                    startTimer={startTimer}
                    instructions={instructions}
                />
            )}
        </>
    );
};

export default Countdown;