import React from 'react'
import './footer.css'

export default props => {
    return (
        <section className="footer-area section-bg-2 padding-bottom-40px">
            <div className="container">
                <div className="copyright-content">
                    <div className="row align-items-center">
                        <div className="col-lg-12 text-center">
                            {/* <p className="copy__desc">&copy; 2020 Rocketmat. All Rights Reserved by <a href="https://rocketmat.com">Rocketmat.</a></p> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}