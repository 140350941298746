import React, { Component } from 'react'
import AuthService from '../../api/authService'
import $ from 'jquery'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { isEmail } from "validator";
import CheckButton from "react-validation/build/button";
import './register.css'

const required = value => {
    if (!value) {
        return (
            <div className="validation-msg" role="alert">
                Campo obrigatório
            </div>
        );
    }
}

const email = value => {
    if (value != localStorage.getItem('user_email')) {
        return (
            <div className="validation-msg" alert>
                O e-mail digitado é diferente.
            </div>
        );
    }
};

const password = value => {
    if (value.length < 6) {
        return (
            <div className="validation-msg" alert>
                A senha deve conter no mínimo 6 digitos.
            </div>
        )
    }
}

const confirmPassword = (value, props) => {
    if (value != props.confirmPassword) {
        return (
            <div className="validation-msg" alert>
                O valor digitado é diferente.
            </div>
        )
    }
}

export default class Register extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            name: null,
            email: null,
            password: null,
            confirmPassword: null
        }

        this.onChangeName = this.onChangeName.bind(this)
        this.onChangeEmail = this.onChangeEmail.bind(this)
        this.onChangePassword = this.onChangePassword.bind(this)
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handlePast = this.handlePast.bind(this)

    }

    onChangeName(e) {
        this.setState({
            ...this.state,
            name: e.target.value
        })
    }

    onChangeEmail(e) {
        this.setState({
            ...this.state,
            email: e.target.value
        })
    }

    onChangePassword(e) {
        this.setState({
            ...this.state,
            password: e.target.value
        })
    }

    onChangeConfirmPassword(e) {
        this.setState({
            ...this.state,
            confirmPassword: e.target.value
        })
    }

    handlePast(e) {
        e.preventDefault()
    }

    handleSubmit(e) {
        e.preventDefault()
        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            $('.preloader').show();
            AuthService.registerUser(this.state.name, this.state.email, this.state.password)
                .then(
                    response => {
                        if (response.status == 201) {
                            localStorage.removeItem('user_email')
                            this.props.authenticateCandidate()
                        }
                    },
                    error => {

                    }
                )
        }
    }

    componentDidMount() {
        $('.preloader').delay('500').fadeOut(1000);
    }

    render() {
        return (
            <div>
                <section className="breadcrumb-area-common">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-content">
                                    <div className="section-heading">
                                        <h2 className="section__title__login">Sistema de provas online do</h2>
                                        <h2 className="section__title">{this.props.clientInfo.name}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="admission-area section--padding">
                    <div className="container">
                        <div className="row margin-top-30px">
                            <div className="col-lg-10 mx-auto">
                                <div className="card-box-shared">
                                    <div className="card-box-shared-title text-center">
                                        <h3 className="widget-title font-size-25">Preencha o fomulário para se registrar</h3>
                                    </div>
                                    <div className="card-box-shared-body">
                                        <div className="contact-form-action">
                                            <Form onSubmit={this.handleSubmit} ref={c => { this.form = c; }}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="input-box">
                                                            <label className="label-text">Nome<span className="primary-color-2 ml-1">*</span></label>
                                                            <div className="form-group">
                                                                <Input className="form-control" type="text" name="name" value={this.state.name} placeholder="Digite seu nome" onChange={this.onChangeName} validations={[required]} />
                                                                <span className="la la-user input-icon"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="input-box">
                                                            <label className="label-text">E-mail<span className="primary-color-2 ml-1"></span></label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="email" name="" value={localStorage.getItem('user_email')} readOnly={true} />
                                                                <span className="la la-envelope input-icon"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="input-box">
                                                            <label className="label-text">Confirme seu e-mail<span className="primary-color-2 ml-1">*</span></label>
                                                            <div className="form-group">
                                                                <Input className="form-control" type="email" name="email" value={this.state.email} placeholder="Digite seu e-mail" onChange={this.onChangeEmail} validations={[required, email]} onPaste={this.handlePast} />
                                                                <span className="la la-envelope input-icon"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="input-box">
                                                            <label className="label-text">Senha<span className="primary-color-2 ml-1">*</span></label>
                                                            <div className="form-group">
                                                                <Input className="form-control" type="password" name="password" value={this.state.password} placeholder="******" onChange={this.onChangePassword} validations={[required, password]} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="input-box">
                                                            <label className="label-text">Confirme sua senha<span className="primary-color-2 ml-1">*</span></label>
                                                            <div className="form-group">
                                                                <Input className="form-control" type="password" name="confirmPassword" value={this.state.confirmPassword} placeholder="******" confirmPassword={this.state.password} onChange={this.onChangeConfirmPassword} validations={[required, confirmPassword]} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="btn-box">
                                                            <button className="theme-btn" type="submit">Registrar</button>
                                                            <CheckButton style={{ display: "none" }} ref={c => { this.checkBtn = c; }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

