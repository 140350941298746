import React, { Component } from 'react'


export default props => {

    let cssClass = ''
    let msg = ''

    switch (props.msgType) {
        case 'incorrect':
            cssClass = 'alert alert-danger'
            msg = 'O seu pré-registro não foi encontrado.'
            break

        case 'conflict':
            cssClass = 'alert alert-danger'
            msg = 'O seu pré-registro já foi utilizado para essa prova.'
            break

        case '401':
            cssClass = 'alert alert-danger'
            msg = 'Você não está autenticado.'
            break

        case '403':
            cssClass = 'alert alert-danger'
            msg = 'Você não possui permissão para está operação.'
            break

        case '406':
            cssClass = 'alert alert-danger'
            msg = 'Os dados informados não foram aceitos.'
            break

        case '409':
            cssClass = 'alert alert-danger'
            msg = 'Esse registro já existe.'
            break

        case '423':
            cssClass = 'alert alert-danger'
            msg = 'Este registro está bloqueado, indisponível ou desatualizado'
            break

        case '500':
            cssClass = 'alert alert-danger'
            msg = 'Ocorreu um erro interno ao processar a solicitação.'
            break

        default:
            msg = ""
            break
    }

    return (
        <div className={cssClass} role="alert">
            {msg}
        </div>
    )

}