import axios from 'axios'
import AuthHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_SCHEME + '://' + process.env.REACT_APP_API_HOST + (process.env.REACT_APP_API_PORT == 80 ? '' : (':' + process.env.REACT_APP_API_PORT)) + process.env.REACT_APP_API_ENDPOINT

class HttpRequests {

  //BEGIN: Quizzes
  async validateQuiz(uuid) {
    return await axios({
      method: 'get',
      url: API_URL + '/candidates/quizzes/' + uuid 
    })
  }

  async retrieveQuiz(uuid) {
    return await axios({
      method: 'get',
      url: API_URL + '/candidates/quizzes/' + uuid ,
      headers: AuthHeader()
    })
  }

  async realizeApplication(data) {
    return await axios({
      method: 'post',
      url: API_URL + '/candidates/quizzes/' + localStorage.getItem('quiz_id'),
      headers: AuthHeader(),
      data: data
    })
  }

  async retrieveQuestion(uuid) {
    return await axios({
      method: 'get',
      url: API_URL + '/candidates/quizzes/' + uuid + '/question',
      headers: AuthHeader()
    })
  }

  async postAnswer(data) {
    return await axios({
      method: 'post',
      url: API_URL + '/candidates/quizzes/' + localStorage.getItem('quiz_id') + '/answer',
      headers: AuthHeader(),
      data: data
    })
  }

  async retrieveResults(uuid) {
    return await axios({
      method: 'get',
      url: API_URL + '/candidates/quizzes/' + uuid + '/results',
      headers: AuthHeader()
    })
  }

  //BEGIN: Impersonation
  async retrieveImpersonation(hash) {
    return await axios({
      method: 'get',
      url: API_URL + '/impersonation/' + hash,
      headers: AuthHeader()
    })
  }
}

export default new HttpRequests()
