import React, { Component } from 'react'
import AnswerOptions from './answerOptions'
import AnswerActions from './answerActions'

export default class Answers extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedAwnswerId: null,
            skipped: null,
            msgType: null,
            timeout: false,
            answerCharacterLimit: this.props.questionData.type === 'multiple_choice' ? null : this.props.questionData.answer_character_limit,
            remainingCharacters: this.props.questionData.answer_character_limit
        }

        this.selectAwnswer = this.selectAwnswer.bind(this)
        this.answerQuestion = this.answerQuestion.bind(this)
        this.skipQuestion = this.skipQuestion.bind(this)
        this.showMsg = this.showMsg.bind(this)
    }

    selectAwnswer(value) {
        if (this.state.answerCharacterLimit != null) {
            this.setState({
                ...this.state,
                msgType: value.length <= this.state.answerCharacterLimit ? '' : 'characters-exceded',
                remainingCharacters: this.state.answerCharacterLimit - value.length,
                selectedAwnswerId: value,
                skipped: false
            })
        } else {
            this.setState({
                ...this.state,
                selectedAwnswerId: value,
                skipped: false
            })
        }

        this.props.handleAnswerCadidateTime(null, value)
    }

    answerQuestion() {
        if (this.props.questionData.type == 'open_ended' && this.state.remainingCharacters < 0) {
            return
        }
        this.props.nextQuestion(this.state.selectedAwnswerId, this.state.skipped, false)

        this.setState({
            selectedAwnswerId: null,
            skipped: null,
            msgType: null,
            timeout: false,
            answerCharacterLimit: null,
            remainingCharacters: null,
        })
    }

    skipQuestion() {
        this.setState({
            ...this.state,
            selectedAwnswerId: null,
            skipped: true,
            msgType: null
        })

        this.props.nextQuestion(null, true, false)
    }

    showMsg() {
        this.setState({
            msgType: 'not answered'
        })
    }

    componentWillReceiveProps(nextProps) {
        
        this.setState({
            ...this.state,
            answerCharacterLimit: nextProps.questionData.type === 'multiple_choice' ? null : nextProps.questionData.answer_character_limit,
            remainingCharacters: nextProps.questionData.type === 'multiple_choice' ? null : nextProps.questionData.answer_character_limit,
            msgType: null
        })
    }

    render() {
        return (
            <div>
                <AnswerActions type={this.props.questionData.type} msgType={this.state.msgType} answerQuestion={this.state.selectedAwnswerId == null || this.state.selectedAwnswerId == "" ? this.showMsg : this.answerQuestion} skipQuestion={this.skipQuestion} allowSkip={this.props.questionData.allowSkip} />
                <AnswerOptions
                    answerCharacterLimit={this.state.remainingCharacters != null ? this.state.remainingCharacters : null}
                    msgType={this.state.msgType}
                    answerOptions={this.props.questionData.answers}
                    type={this.props.questionData.type}
                    allowSkip={this.props.questionData.allowSkip}
                    selectAwnswer={this.selectAwnswer}
                    selectedAwnswerId={this.state.selectedAwnswerId}
                />
            </div>
        )
    }
}