import React from 'react'
import { Trans } from 'react-i18next';

export default props => {

    const enableSkipButton = () => {

        if(props.allowSkip === true){
            return (
                <button className="btn-skip theme-btn theme-btn-light mr-2" onClick={props.skipQuestion}><Trans>Skip</Trans></button>
            )
        }
    }
    
    return (
        <div className="quiz-action-nav bg-white py-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="quiz-action-content d-flex align-items-center justify-content-between">
                            <ul className="quiz-nav d-flex align-items-center">
                                <li><i className="la la-sliders font-size-20 mr-2"></i>{props.type == 'open_ended' ? <Trans>Enter your answer</Trans> : <Trans>Select a correct answer</Trans>}</li>
                            </ul>
                            <div className="quiz-nav-btns">
                                {enableSkipButton()}
                                <button className="btn-next-question theme-btn theme-btn-light-custom bg-color-1 text-white" onClick={props.answerQuestion}><Trans>Confirm reply</Trans><i className="la la-angle-right ml-1"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}