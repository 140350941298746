export default function authHeader() {
    const tokens = JSON.parse(localStorage.getItem('tokens'));
    
    if (tokens) {
        return { 
                'Authorization': `Bearer ${tokens.token}`,
            };
    } else {
        return {};
    }
}
