import React, {useEffect, useState} from 'react'
import './question.css'
import Countdown from './countdown'
import {Trans} from 'react-i18next'
import ModalImage from "react-modal-image";

export default function Question(props) {
    const [questionData, setQuestionData] = useState(null)

    const questionDescriptionCss = () => {
        if (questionData.image) {
            return 'section__desc question-wording question-description text-color-rgba mb-0 pt-3 qestion-image'
        } else {
            return 'section__desc question-wording question-description quiz-desc-no-image text-color-rgba mb-0 pt-3'
        }
    }

    const questionMedia = () => {
        if (questionData.image) {
            return (<ModalImage className={'small-question-image'} small={questionData.image}
                                large={questionData.image}/>)
        }
        return (<></>)
    }

    useEffect(() => {
        setQuestionData(props.questionData)
    }, [props.questionData])

    return (<>
        {questionData && (<div className='question-container'>
            <div className='row col-12 justify-content-between'>
                <h2 className="section__title text-white"><Trans>Question</Trans><span> {questionData.order} </span>
                    <Trans>of</Trans> {questionData.amountQuestions}</h2>
                <p className="lead text-white font-size-25 font-weight-medium mb-0 align-self-center">
                    <Countdown
                        instructions={questionData.instructions}
                        timer={questionData.time}
                        handleAnswerCadidateTime={props.handleAnswerCadidateTime} nextQuestion={props.nextQuestion}
                    />
                </p>
            </div>
            <div className='row'>
                <div className='quiz-content col-12'>
                    <div className='question-area'>
                        <p className={questionDescriptionCss()}>
                            <div dangerouslySetInnerHTML={{__html: questionData.description}}></div>
                        </p>
                        <div className='question-image'>
                            {questionMedia()}
                        </div>
                    </div>
                </div>
            </div>
        </div>)}
    </>)
}