import React, { Component } from 'react'


export default props => {

    let cssClass = ''
    let msg = ''

    switch (props.msgType) {
        case 'login incorrect':
            cssClass = 'alert alert-danger'
            msg = 'Usuário ou senha incorretos'
            break

        case 'login successful':
            cssClass = 'alert alert-success'
            msg = 'Redirecionando...'
            break

        case 'recaptcha-false':
            cssClass = 'alert alert-danger'
            msg = 'Marque a opção "Não sou um robô"'
            break

        default:
            return msg = ""
    }

    return (
        <div className={cssClass} role="alert">
            {msg}
        </div>
    )

}