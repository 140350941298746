import axios from "axios";
import keycloak from '../keycloak'


const API_URL = process.env.REACT_APP_API_SCHEME + '://' + process.env.REACT_APP_API_HOST + (process.env.REACT_APP_API_PORT == 80 ? '' : (':' + process.env.REACT_APP_API_PORT)) + process.env.REACT_APP_API_ENDPOINT

class AuthService {

  onKeycloakEvent = async (event, error) => {
    if (event === 'onAuthSuccess') {
      keycloak.loadUserProfile()
        .then(function (profile) {
          localStorage.setItem('user_info', JSON.stringify(profile, null, '  '))
        }).catch(function () {
          alert('Failed to load user profile');
        });
    }
  }

  onKeycloakTokens = (tokens) => {
    if (localStorage.getItem('tokens')) {
      localStorage.removeItem('tokens')
    }
    localStorage.setItem('tokens', JSON.stringify(tokens, null, '  '))
  }

  login(email, password) {
    return axios
      .post(API_URL + "/auth", {
        email,
        password
      })
      .then(response => {
        if (response.data.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response;
      });
  }

  logout() {
    localStorage.removeItem("user_info");
  }

  registerUser(name, email, password) {
    return axios({
      method: 'post',
      url: API_URL + '/auth/register',
      headers: null,
      data: {
        name: name,
        email: email,
        password: password
      }
    })
      .then(response => {
        if (response.data.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response;
      })
      .catch((error) => {
        return error
      })
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();
