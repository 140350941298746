import React from 'react'
import { Trans } from 'react-i18next';

export default function score(props) {

    const endDate = () => {
        let months = []
        const monthsPtBr = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
        const monthsEnUs = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const monthsEsEs = ['Ene', 'Feb', 'Mar', 'Abr', 'Mayo', 'Jun', 'Jul', 'Ago', 'Sept', 'Cct', 'Nov', 'Dic'];

        switch (props.lang) {
            case 'pt_br':
                months = monthsPtBr
                break

            case 'en_us':
                months = monthsEnUs
                break

            case 'es_es':
                months = monthsEsEs
                break
        }

        const a = new Date(props.endDate * 1000)
        const year = a.getFullYear();
        const month = months[a.getMonth()];
        const date = a.getDate();
        const hour = a.getHours();
        const min = a.getMinutes();
        const sec = a.getSeconds();
        const time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
        return time
    }

    if (props.results == true) {
        return (
            <div className="row">
                <div className="col-lg-12">
                    <div className="quiz-content text-center">
                        <p className="lead font-weight-regular font-size-18 text-color-rgba mb-0 pb-2"><Trans>Sent in</Trans> {endDate()}</p>
                        <h2 className="section__title text-white padding-bottom-30px"><Trans>Result</Trans>: {(props.score * 100).toFixed(2)}</h2>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="row">
                <div className="col-lg-12 column-td-half">
                    <div className="quiz-content quiz-result-content text-center">
                        <h2 className="section__title text-white"><Trans>Thank you for participating!</Trans></h2>
                    </div>
                </div>
            </div>
        )
    }


}