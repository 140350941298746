import React, { Component } from 'react'
import './answerOptions.css'
import AnswerMessage from './answerMessage'
import { Trans } from 'react-i18next';


export default class AnswerOptions extends Component {

    constructor(props) {
        super(props)

        this.state = {
            answerOptions: this.props.answerOptions,
            type: this.props.type,
            selectedAnswer: this.props.selectedAnswerId
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e) {
        const VALUE = e.target.value

        
        this.props.selectAwnswer(VALUE)
    }

    renderAnswers() {

        if (this.props.type === 'open_ended') {
            return (
                <div className="form-group">
                    <textarea
                        className="form-control"
                        rows={3}
                        value={this.state.selectedAnswer == null ? '' : this.state.selectedAnswer}
                        onChange={this.handleChange}
                    />
                </div>
            )
        } else {

            const letters = ['a)','b)','c)','d)','e)','f)','g)','h)','i)','j)','k)','l)','m)','n)','o)','p)','q)','r)','s)','t)','u)','v)','w)','x)','y)','z)']

            const answerOptions = this.props.answerOptions || []
            return answerOptions.map((answer, i) => (
                <div key={answer.uuid}>
                    <div className="answer-option">
                        <label htmlFor={answer.id} className="radio-trigger">
                            <span className="answer-letter">{letters[i]}</span>
                            <input type="radio" id={answer.uuid} value={answer.uuid} name="radio" onChange={this.handleChange} />
                            <span className="checkmark"></span>
                            <span className="widget-title font-size-18">
                                {answer.image ? <img src={answer.image} /> : answer.description }
                            </span>
                        </label>
                    </div>
                </div>
            ))
        }
    }

    renderAnswerCharacterLimit() {


        if (this.props.answerCharacterLimit != null) {
            return (
                <span> <Trans>Characters remaining</Trans>: {this.props.answerCharacterLimit}</span>
            )
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ...this.state,
            selectedAnswer: nextProps.selectedAwnswerId
        })
    }

    render() {

        const paddingTop = this.props.msgType == null ? "padding-top-80px" : "padding-top-10px"

        return (
            <div>
                <div className={"quiz-ans-wrap padding-bottom-80px " + paddingTop}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <AnswerMessage
                                    allowSkip={this.props.allowSkip}
                                    msgType={this.props.msgType}
                                    answerType={this.props.type}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="quiz-ans-content">
                                    <h3 className="widget-title"><Trans>Your answer</Trans>:</h3>
                                    {this.renderAnswerCharacterLimit()}
                                    <ul className="py-3">
                                        {this.renderAnswers()}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
