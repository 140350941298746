import React from 'react'
import './KnowledgeAreaTable.css'
import Result from './result'
import { Trans } from 'react-i18next';

export default props => {
    return (
        <div className="quiz-ans-wrap padding-top-80px padding-bottom-80px">
            <div className="container">
                <div className="row mb-5">
                    <div className="col-lg-12">
                        <div class="final-message">
                            <h3>
                                <div dangerouslySetInnerHTML={{ __html: props.finalMessage }}></div>
                            </h3>
                        </div>
                    </div>
                </div>
                {props.knowledgeAreaResults ? <ResultsTable knowledgeAreaResults={props.knowledgeAreaResults} /> : ''}
            </div>
        </div>
    )
}

function ResultsTable(props) {
    const rendererKnowledgeAreaResults = () => {
        const knowledgeAreaResults = props.knowledgeAreaResults || []
        return knowledgeAreaResults.map(result => (
            <div key={result.knowledge_area.uuid} className="list-group-item d-flex">
                <div className="flex-grow-1">
                    <h5 className="font-size-16">{result.knowledge_area.name}</h5>
                </div>
                <div className="text-center">
                    <span className="d-block line-height-20 font-weight-semi-bold mb-n1">{(result.score * 100).toFixed(2)}</span>
                    <small className="text-uppercase primary-color-3"><Trans>Result</Trans></small>
                </div>
            </div>
        ))
    }

    if (props.knowledgeAreaResults.length > 1) {
        return (
            <div className="row mb-5">
                <div className="col-lg-12">
                    <ul className="quiz-nav d-flex align-items-center pb-4">
                        <li>
                            <div className="d-flex align-items-center">
                                <span className="title-table-knowledge-results font-weight-semi-bold"><Trans>Evaluation by Knowledge Area</Trans></span>
                            </div>
                        </li>
                    </ul>
                    <div className="list-group">
                        {rendererKnowledgeAreaResults()}
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div></div>
        )
    }

}
