import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './instructionsModal.css';

const InstructionModal = ({ instructions, startTimer }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    startTimer();
  };

  useEffect(() => {
    if (instructions) {
      handleShow();
    }
  }, [instructions]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        backdropClassName='background-white'
      >
        <Modal.Header>
          <Modal.Title>Atenção!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text_body">
          <div dangerouslySetInnerHTML={{ __html: instructions }} className="notranslate"></div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InstructionModal;
