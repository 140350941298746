import React from 'react'
import { useEffect } from 'react'
import './home.css'
import $ from 'jquery'
import { useTranslation, Trans } from 'react-i18next';

export default function Home(props) {

    const { t, i18n } = useTranslation();

    useEffect(() => {
        $('.preloader').delay('500').fadeOut(1000);
    })

    return (
        <div>
            <section className="slider-area">
                <div className="owl-dot-and-nav">
                    <div className="single-slide-item slide-bg1-custom">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-heading">
                                        <h2 className="section__title text-white"><Trans>Welcome quiz</Trans><br /> {props.clientInfo.name}</h2>
                                    </div>
                                    <div className="btn-box d-flex align-items-center">
                                        <button className="theme-btn theme-btn-hover-light" onClick={() => {props.changeContent('quiz',null)}}>{t('Start')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}