import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const Resources = {

    pt_br: {
        translation: {
            "Welcome quiz": "Bem vindo(a) ao Sistema de <br /> provas online",
            "Start": "Começar",
            "Question": "Questão",
            "of": "de",
            "Exit": "Sair",
            "Skip": "Pular",
            "Enter your answer": "Digite sua resposta",
            "Select a correct answer": "Selecione uma resposta correta",
            "Confirm reply": "Confirmar resposta",
            "Your answer": "Sua resposta",
            "Characters remaining": "Caracteres restantes",
            "Select an option!": "Selecione uma opção",
            "Type your answer or skip the question!": "Digite sua resposta ou pule a questão",
            "Select an option or skip the question!": "Selecione uma opção ou pule a questão",
            "Type your answer!": "Digite sua resposta",
            "Number of characters executed.": "Número de caracteres excedidos.",
            "Sent in": "Enviado em",
            "Result": "Resultado",
            "Evaluation by Knowledge Area": "Avaliação por Área de Conhecimento",
            "Thank you for participating!": "Obrigado por participar",
            "Complete your register" : "Complete seu registro",
            "Submit" : "Enviar",
            "Restart" : "Reiniciar"
        }
    },
    en_us: {
        translation: {
            "Welcome quiz": "Welcome to our Online <br /> Testing Platform",
            "Start": "Start",
            "Question": "Question",
            "of": "of",
            "Exit": "Exit",
            "Skip": "Skip",
            "Enter your answer": "Type your answer",
            "Select a correct answer": "Select the correct answer",
            "Confirm reply": "Confirm",
            "Your answer": "Your answer",
            "Characters remaining": "Characters remaining",
            "Select an option!": "Select an option!",
            "Type your answer or skip the question!": "Type your answer or skip the question",
            "Select an option or skip the question!": "Select an option or skip the question",
            "Type your answer!": "Type your answer!",
            "Number of characters executed.": "Character limit exceeded",
            "Sent in": "Sent on",
            "Result": "Result",
            "Evaluation by Knowledge Area": "Evaluation by Knowledge Area",
            "Thank you for participating!": "Thank you for participating",
            "Complete your register" : "Complete your register",
            "Submit" : "Submit",
            "Restart" : "Restart"
        }
    },
    es_es: {
        translation: {
            "Welcome quiz": "Quiz de bienvenida",
            "Start": "Empezar",
            "Question": "Pregunta",
            "of": "Para",
            "Exit": "Salir",
            "Skip": "Pasar",
            "Enter your answer": "Escribe tu respuesta",
            "Select a correct answer": "Escoge una respuesta correcta",
            "Confirm reply": "Confirma tu respuesta",
            "Your answer": "Tu respuesta",
            "Characters remaining": "Caracteres restantes",
            "Select an option!": "Escoge una opción",
            "Type your answer or skip the question!": "Escribe tu respuesta o pasa la pregunta",
            "Select an option or skip the question!": "Escoge una opción o pasa la pregunta",
            "Type your answer!": "Escribe tu respuesta",
            "Number of characters executed.": "Número de caracteres excedidos.",
            "Sent in": "Enviado en",
            "Result": "Resultado",
            "Evaluation by Knowledge Area": "Evaluación por el área de conocimiento",
            "Thank you for participating!": "Gracias por tu participación!",
            "Complete your register" : "Completa tu registro",
            "Submit" : "Entregar",
            "Restart" : "Reiniciar"
        }
    }
};

i18n
  .use(initReactI18next)
  
  export { Resources }
  export default i18n
