import React, { Component } from 'react';
import $ from 'jquery'
import Form from "react-validation/build/form";
import InputMaskCustom from "./InputMaskCustom";
import CheckButton from "react-validation/build/button";
import './application.css'
import ApplicationMessage from './applicationMessage'
import HttpRequests from '../../api/httpRequests'
import { Trans } from 'react-i18next';

const URL = process.env.REACT_APP_API_SCHEME + '://' + process.env.REACT_APP_API_HOST + (process.env.REACT_APP_API_PORT == 80 ? '' : (':' + process.env.REACT_APP_API_PORT)) + process.env.REACT_APP_API_ENDPOINT

const required = (value, props) => {
    if (props.requiredField) {
        if (!value) {
            return (
                <div className="validation-msg">
                    Campo obrigatório
                </div>
            );
        }
    } else {
        return
    }
}

const regexValidation = (value, props) => {
    if (value != '' && !value.match(props.regexValidation)) {
        return (
            <div className="validation-msg">
                Inválido
            </div>
        );
    }
};

export default class Application extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            additionalFields: [],
            values: [],
            msgType: null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async getAdditionalFields() {
        try {
            const response = await HttpRequests.retrieveQuiz(localStorage.getItem('quiz_id'))

            if (response.status == 206 || (response.status === 200 && response.data.data.status === 'in_testing')) {
                this.props.handleRestart()
                
                const additional_fields = response.data.data.additional_fields.filter(function (obj) {
                    return (obj.integration_field === false || obj.integration_field === undefined) && (obj.status === 'active' || obj.status === undefined);
                });
                
                if (additional_fields.length === 0 && this.props.urlParamsObj.urlObject.access_key !== undefined) {
                    try {
                        const response = await HttpRequests.realizeApplication({
                            external_id: this.props.urlParamsObj.urlObject.external_id, 
                            additional_fields: [
                                {
                                    'id': 'access_key',
                                    'value': this.props.urlParamsObj.urlObject.access_key
                                }
                            ]
                        })

                        if (response.status == 204) {
                            window.history.replaceState(null, 'Instructions', '/' + localStorage.getItem('quiz_id') + '/application' + this.props.urlParamsObj.urlString)
                            this.props.changeContent('quiz', null)
                        }

                    } catch (error) {
                        this.props.changeContent('403', null)
                    }
                }

                if (additional_fields.length === 0 && this.props.urlParamsObj.urlObject.access_key === undefined) {
                    try {
                       const response = await HttpRequests.realizeApplication({})
                        
                        if (response.status == 204) {
                            window.history.replaceState(null, 'Instructions', '/' + localStorage.getItem('quiz_id') + '/instructions')
                            this.props.changeContent('quiz', null)
                        }
                        
                    } catch (error) {
                        this.props.changeContent('500', null)
                    }

                } else {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        additionalFields: additional_fields
                    })
                }
            }

        } catch (error) {
            this.props.changeContent('500', null)
        }
        finally {
            $('.preloader').delay('500').fadeOut(1000);
        }
    }

    showAddtionalFields() {
        const additionalFields = this.state.additionalFields || []
        return additionalFields.map((fields, i) => (
            <div key={i} className="col-lg-12 ">
                <div className="input-box">
                    <label className="label-text">{fields.label}<span className="primary-color-2 ml-1">{fields.required ? '*' : ''}</span></label>
                    <div className="form-group">
                        <InputMaskCustom
                            type="text"
                            className="form-control"
                            name={fields.label}
                            placeholder={fields.instructions}
                            onChange={this.handleChange.bind(this, i)}
                            requiredField={fields.required}
                            regexValidation={fields.regex_validation}
                            validations={[required, regexValidation]}
                        />
                    </div>
                </div>

            </div>
        ))
    }

    async realizeApplication() {
        $('.preloader').show()
        this.setState({
            ...this.state,
            msgType: null
        })
        let fields = { 'additional_fields': [] }
        const valuesInputs = this.state.values
        const additionalFields = this.state.additionalFields
        valuesInputs.forEach(function (value, index) {
            if (value != '') {
                fields['additional_fields'].push({ 'id': additionalFields[index]['id'], 'value': value })
            }
        })

        if (this.props.urlParamsObj.urlObject.access_key) {
            fields['additional_fields'].push({ 'id': 'access_key', 'value': this.props.urlParamsObj.urlObject.access_key })
        }

        if (this.props.urlParamsObj.urlObject.external_id) {
            fields['external_id'] = this.props.urlParamsObj.urlObject.external_id
        }

        try {
            const response = await HttpRequests.realizeApplication(fields)
            if (response.status == 204) {
                window.history.replaceState(null, 'Instructions', '/' + localStorage.getItem('quiz_id') + '/instructions')
                this.props.changeContent('quiz', null)
            }

        } catch (error) {

            switch (error.response.status) {

                case 403:
                    this.setState({
                        ...this.state,
                        msgType: 'incorrect'
                    })
                    break

                case 409:
                    this.setState({
                        ...this.state,
                        msgType: 'conflict'
                    })
                    break

                case 423:
                    this.setState({
                        ...this.state,
                        msgType: '423'
                    })
                    break

                default:
                    this.setState({
                        ...this.state,
                        msgType: '500'
                    })

            }

        } finally {
            $('.preloader').delay('500').fadeOut(1000);
        }
    }

    handleChange(i, event) {
        let values = [...this.state.values];
        values[i] = event.target.value;
        this.setState({ values });
    }

    handleSubmit(e) {
        e.preventDefault()
        this.form.validateAll()

        if (this.checkBtn.context._errors.length === 0) {
            this.realizeApplication()
        }
    }

    componentDidMount() {
        this.getAdditionalFields()
    }

    render() {
        if (this.state.isLoading == true) {
            return (
                <div></div>
            )
        }
        return (
            <div>
                <section className="application-breadcrumb-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-content">
                                    <div className="section-heading">
                                        <h2 className="section__title__custom"><Trans>Complete your register</Trans></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sign-up section--padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 mx-auto">
                                <div className="card-box-shared">
                                    <div className="card-box-shared-body">
                                        <div className="contact-form-action">
                                            <Form onSubmit={this.handleSubmit} ref={c => { this.form = c; }}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <ApplicationMessage msgType={this.state.msgType} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {this.showAddtionalFields()}
                                                    <div className="col-lg-12 ">
                                                        <div className="btn-box">
                                                            <button className="btn btn-block theme-btn" type="submit"><Trans>Submit</Trans></button>
                                                            <CheckButton
                                                                style={{ display: "none" }}
                                                                ref={c => {
                                                                    this.checkBtn = c;
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}