import React from 'react'
import { useHistory } from 'react-router-dom'
import AuthService from '../../api/authService'
import { Trans } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web'


export default props => {

    let history = useHistory()
    const { keycloak } = useKeycloak()

    const logoff = () => {
        const urlParamsJson = localStorage.getItem('urlParamsObj')
        const urlParamsObj = JSON.parse(urlParamsJson)

        let url_string = ''
        if (urlParamsObj.urlString) {
            url_string = `/${localStorage.getItem('quiz_id')}/application${urlParamsObj.urlString}`
        }
        else {
            url_string = `/${localStorage.getItem('quiz_id')}`
        }

        AuthService.logout()
        history.push(url_string)
        keycloak.logout()

    }

    return (
        <section className="error-area padding-top-70px padding-bottom-90px">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 mx-auto">
                        <div className="error-content text-center">
                            <div style={{minHeight: '418px'}} className="section-heading">
                                <h1>Erro 403</h1>
                                <h3 className="section__title pb-3">Whoops! Acesso não autorizado</h3>
                                <p className="section__desc">
                                    Contate o administrador.
                                </p>
                                {localStorage.getItem('user_info') ? <button type="button" className="theme-btn" onClick={logoff}><Trans>Exit</Trans></button> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
