import React, { Component } from 'react'
import $ from 'jquery'
import ShowResults from './showResults'
import KnowledgeAreaTable from './KnowledgeAreaTable'
import HttpRequests from '../../api/httpRequests'
import './result.css'


const urlParamsJson = localStorage.getItem('urlParamsObj')
const urlParamsObj = JSON.parse(urlParamsJson)

export default class Result extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            results: null,
            processingResults: false
        }
    }

    async getResults() {

        try {

            const response = await HttpRequests.retrieveResults(localStorage.getItem('quiz_id'))

            switch (response.status) {
                case 200:
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        results: response.data.data
                    })
                    $('.preloader').delay('500').fadeOut(1000);
                    break
                case 202:
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        processingResults: true
                    })
                    $('.preloader').delay('500').fadeOut(1000)
                    break
            }

        } catch (error) {
            switch (error.response.status) {
                case 403:
                    this.setState({
                        isLoading: false
                    })
                    $('.preloader').delay('500').fadeOut(1000)
                    break
            }
        }
    }

    renderResults() {
        const redirectUrl = this.props.urlParamsObj.urlObject.redirect_url
        
        if (this.props.urlParamsObj.urlObject.redirect_url) {
            window.setTimeout(function () {
                window.location.href = redirectUrl
            }, 15000)
        }

        if (this.state.processingResults == false) {
            return this.props.contentData.show_results == true ? <ShowResults lang={this.props.contentData.lang} results={true} endDate={this.state.results.end_date} score={this.state.results.score} /> : <ShowResults results={false} />
        } else {
            return <ShowResults results={false} />
        }
    }

    componentDidMount() {
        this.getResults()
    }

    render() {
        if (this.state.isLoading == true) {
            return (
                <div></div>
            )
        } else {
            return (
                <section className="quiz-wrap">
                    <div className="quiz-content-wrap bg-black padding-top-60px padding-bottom-60px">
                        <div className="container">
                            {this.renderResults()}
                        </div>
                    </div>
                    {this.props.urlParamsObj.urlObject.redirect_url ?
                        <div className='redirect-area'>
                            <p>Você será redirecionado para a plataforma de recrutamento e seleção da AMBEV.</p>
                            <p><a href={this.props.urlParamsObj.urlObject.redirect_url} class="theme-btn">Ir agora</a></p>
                        </div>
                        : ''}
                    <KnowledgeAreaTable
                        finalMessage={this.props.contentData.final_message}
                        knowledgeAreaResults={this.state.results ? this.state.results.results : this.state.results}
                        processingResults={this.state.processingResults ? 'Sua prova será avaliada em breve' : ''}
                    />
                </section>
            )
        }
    }

}


