import React from 'react'
import { control } from 'react-validation'
import MaskedInput from 'react-text-mask'


const Input = ({ error, isChanged, isUsed, ...props }) => {
    const generateMask = (regexValidation) => {
        if (regexValidation && regexValidation === '^[0-9]{3}\\.[0-9]{3}\\.[0-9]{3}-[0-9]{2}$') {
            return [/[0-9]/,/[0-9]/,/[0-9]/,'.',/[0-9]/,/[0-9]/,/[0-9]/,'.',/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/]
        } else {
            return false
        }
    }

    return (
        <div>
            <MaskedInput
                {...props}
                mask={generateMask(props.regexValidation)}
            />
            {isChanged && isUsed && error}
        </div>
    )
}

const InputMaskCustom = control(Input)

export default InputMaskCustom
