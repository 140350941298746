import React from 'react'
import $ from 'jquery'
import { useParams } from 'react-router-dom'
import AppFactory from './AppFactory'
import Preloader from './features/template/preloader'
import './features/template/custom.css'
import HttpRequests from './api/httpRequests'
import { useKeycloak } from '@react-keycloak/web'
import i18n, { Resources } from './i18n'

const App = (props) => {

  let { quizId } = useParams()
  const { keycloak } = useKeycloak()
  const urlParamsObj = JSON.parse(localStorage.getItem('urlParamsObj'))

  const [state, setState] = React.useState({
    isLoading: true,
    quizId: quizId,
    content: null,
    client: null,
    quizStatus: null
  })

  const validateQuiz = async () => {
    try {
      let response = {}

      if (keycloak.authenticated) {
        response = await HttpRequests.retrieveQuiz(state.quizId)
      } else {
        response = await HttpRequests.validateQuiz(state.quizId)
      }

      localStorage.setItem("quiz_id", state.quizId);

      const resources = Resources

      i18n.init({
        resources,
        lng: response.data.data.lang,
        keySeparator: false,
        interpolation: {
          escapeValue: false
        }
      })

      if (keycloak.authenticated) {
        setState({
          ...state,
          isLoading: false,
          content: 'quiz',
          client: response.data.data.client,
          quizStatus: response.data.data.status
        })

      } else {
        setState({
          ...state,
          isLoading: false,
          content: 'home',
          client: response.data.data.client
        })

        if (urlParamsObj.urlParamsStr === null) {
          
          window.history.replaceState(null, 'Home', '/' + quizId)
        } else {
          window.history.replaceState(null, 'Home', '/' + quizId + urlParamsObj.urlString)
        }
      }

    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            if (urlParamsObj.urlString === null) {
              window.location.replace('/' + quizId )
            } else {
              window.location.href = urlParamsObj.urlObject.redirect_url
            }
            break

          default:
            setState({
              ...state,
              isLoading: false,
              content: '404'
            })
            localStorage.removeItem("quiz_id")
        }
      } else {
        window.location.replace('/')
      }
    } finally {
      $('.preloader').delay('500').fadeOut(1000);
    }
  }

  React.useEffect(() => {
    if (state.isLoading == true) {

      if (props.result == false) {
        validateQuiz()

      } else {
        setState({
          isLoading: false,
          content: 'result'
        })
      }
    }
  })

  if (state.isLoading === false) {
    return (
      <AppFactory clientInfo={state.client} contentType={state.content} quizId={state.quizId} quizStatus={state.quizStatus} urlParamsObj={urlParamsObj} />
    )
  }

  return (
    <div>
      <Preloader />
    </div>
  )
}

export default App
