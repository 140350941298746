import React, { useEffect, useState } from 'react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom"
import NotFound from '../features/template/404'
import App from '../App'
import AppFactory from '../AppFactory'
import AuthService from '../api/authService'
import keycloak from '../keycloak'
import Preloader from '../features/template/preloader'
import HttpRequests from '../api/httpRequests'


let keycloakInitOptions = {
    onLoad: 'check-sso',
    checkLoginIframe: false
}

export default props => {
    const [loading, setLoading] = useState(true)

    const generateUrl = () => {
        const paramKeys = ['external_id', 'access_key', 'redirect_url', 'impersonation']
        const urlObject = new URL(window.location.href);
        const urlParams = {}
        let urlString = ''

        let sep = '?'
        for (let i = 0; i < paramKeys.length; i++) {
            if (urlObject.searchParams.get(paramKeys[i])) {
                urlParams[paramKeys[i]] = urlObject.searchParams.get(paramKeys[i])
                urlString += sep + paramKeys[i] + '=' + urlObject.searchParams.get(paramKeys[i])
                sep = '&'
            }
        }
        
        localStorage.setItem('urlParamsObj', JSON.stringify({ 'urlString': urlString, 'urlObject': urlParams }));
        
        return { 'urlString': urlString, 'urlObject': urlParams }
    }

    const paramsVerification = async () => {
        const url = generateUrl()

        if (url.urlObject.impersonation !== undefined) {
            try {
                const responseTokens = await HttpRequests.retrieveImpersonation(url.urlObject.impersonation)

                if (responseTokens.status === 200) {
                    keycloakInitOptions.idToken = responseTokens.data.data.id_token
                    keycloakInitOptions.token = responseTokens.data.data.access_token
                    keycloakInitOptions.refreshToken = responseTokens.data.data.refresh_token

                    let tokens = {
                        idToken: responseTokens.data.data.id_token,
                        token: responseTokens.data.data.access_token,
                        refreshToken: responseTokens.data.data.refresh_token
                    }
                    
                    localStorage.setItem('tokens', JSON.stringify(tokens, null, '  '))
                    
                    setLoading(false)
                }

            } catch (error) {
                window.location.href = url.urlObject.redirect_url
            }
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (loading === true) {
            paramsVerification()
        } 
    })

    if (loading === false) {
        return (
            <ReactKeycloakProvider
                authClient={keycloak}
                initOptions={keycloakInitOptions}
                LoadingComponent={<Preloader />}
                onEvent={(event) => AuthService.onKeycloakEvent(event)}
                onTokens={(tokens) => AuthService.onKeycloakTokens(tokens)}
            >
                <Router>
                    <Switch>
                        <Route path="/health">
                            <HealthCheck />
                        </Route>
                        <Route path="/register">
                            <AppFactory contentType='register' />
                        </Route>
                        <Route path="/:quizId/auth" children={<App result={false} quidId={<quizId />} />} />
                        <Route path="/:quizId/instructions" children={<App result={false} quidId={<quizId />} />} />
                        <Route path="/:quizId/questions" children={<App result={false} quidId={<quizId />} />} />
                        <Route path="/:quizId/results" children={<App result={false} quidId={<quizId />} />} />
                        <Route path="/:quizId/register" children={<App result={false} quidId={<quizId />} />} />
                        <Route path="/:quizId" children={<App result={false} quidId={<quizId />} />} />
                        <Route path="/health">
                            <HealthCheck />
                        </Route>
                        <Route path="*">
                            <NotFound />
                        </Route>
                    </Switch>
                </Router>
            </ReactKeycloakProvider>
        )
    } else {
        return <div></div>
    }
}

function HealthCheck(props) {
    return (
        <div>
            <p>
                Workfine! - Status code 200
            </p>
        </div>
    )
}
