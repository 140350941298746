import React, { Component } from 'react'
import { Trans } from 'react-i18next';

export default props => {

    const msg = () => {

        if (props.msgType == 'not answered' && props.answerType == 'multiple_choice' && props.allowSkip == false) {
            return <Trans>Select an option!</Trans>
        }
    
        if (props.msgType == 'not answered' && props.answerType == 'multiple_choice' && props.allowSkip) {
            return <Trans>Select an option or skip the question!</Trans>
        }
    
        if (props.msgType == 'not answered' && props.answerType == 'open_ended' && props.allowSkip == false) {
            return <Trans>Type your answer!</Trans>
        }
    
        if (props.msgType == 'not answered' && props.answerType == 'open_ended' && props.allowSkip == true) {
            return <Trans>Type your answer or skip the question!</Trans>
        }

        if (props.msgType == 'characters-exceded' && props.answerType == 'open_ended') {
            return <Trans>Number of characters executed.</Trans>
        }

    }

    if (props.msgType){
        return (
            <div className="alert alert-danger" role="alert">
                {msg()}
            </div>
        )
    } else {
        return ''
    }

}