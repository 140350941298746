import React, { Component, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AuthService from '../../api/authService'
import './header.css'
import TestBar from './testBar'
import NumberFormat from 'react-number-format'
import { useKeycloak } from '@react-keycloak/web'
import { Trans } from 'react-i18next';


const Header = (props) => {
    let history = useHistory()
    const { keycloak } = useKeycloak()

    const [state, setState] = useState({
        client: props.clientInfo,
        quizStatus: null
    })

    const logoff = () => {
        let url_string = ''
        keycloak.logout()
        AuthService.logout()

        if (props.urlParamsObj.urlString) {
            window.location.href = props.urlParamsObj.urlObject.redirect_url
        } else {
            url_string = `/${localStorage.getItem('quiz_id')}`
            history.push(url_string)
        }
    }

    const formatPhone = () => {

        if (state.client.phone.length == 11) {
            return '(##) #####-####'
        } else {
            return '(##) ####-####'
        }

    }

    return (
        <>
            {props.quizStatus === 'in_testing' ? <TestBar /> : ""}
            <header className="header-menu-area">
                <div className="header-top">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="header-widget">
                                    <ul className="header-action-list">
                                        <li>{state.client.phone ? <div><span className="la la-phone mr-2"></span><NumberFormat value={state.client.phone} displayType={'text'} format={formatPhone()} /></div> : ''}</li>
                                        <li>{state.client.email ? <div><span className="la la-envelope-o mr-2"></span>{state.client.email}</div> : ''}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="header-widget d-flex align-items-center justify-content-end">
                                    <div className="header-right-info">
                                        <ul className="header-action-list">
                                            {localStorage.getItem('user_info') ? <li><a href="#" onClick={logoff}><Trans>Exit</Trans></a></li> : ''}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-menu-content">
                    <div className="container-fluid">
                        <div className="main-menu-content">
                            <div className="row align-items-center">
                                <div className="col-lg-2">
                                    <div className="logo-box">
                                        <a href="index.html" className="logo"><img className="custom-logo" src={state.client.logo ? state.client.logo : "/static/images/rocketmat-logo.png"} alt="" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )

}

export default Header
