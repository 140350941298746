import React, { Component } from 'react'
import './instructions.css'
import { useTranslation, Trans } from 'react-i18next';

export default function instructions(props) {
    if (props.urlParamsObj.urlString) {

        window.history.replaceState(null, 'Instruction', '/' + localStorage.getItem('quiz_id') + '/instruction' + props.urlParamsObj.urlString)
    } else {
        window.history.replaceState(null, 'Instruction', '/' + localStorage.getItem('quiz_id') + '/instruction')
    }

    const additional_fields = props.quizData.additional_fields.filter(function (obj) {
        return obj.status === 'active';
    });

    return (
        <section className="quiz-wrap">
            <div className="quiz-content-wrap bg-black padding-top-60px padding-bottom-60px">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 column-td-half">
                            <div className="quiz-content quiz-result-content text-center">
                                <h2 className="section__title text-white">{props.title}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="quiz-ans-wrap padding-top-80px padding-bottom-80px">
                <div className="container">
                    <div className="row">
                        <div className="col column-td-half column-md-full">
                            <div className="instruction_content">
                                <div dangerouslySetInnerHTML={{ __html: props.instructions }}></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="btn-box pt-5">
                                <div className="start-buttons">
                                    {props.quizData.status === 'in_testing' && props.restarted === false ? <button className="btn btn-secondary" onClick={() => props.checkAdditionalFields(additional_fields.length)}><Trans>Restart</Trans></button> : ''}
                                    <button className="theme-btn" onClick={props.start}><Trans>Start</Trans></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


